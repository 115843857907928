













































































































































































































































































































































































.card-box {
  margin-bottom: 10px;
  .card-item {
    padding: 10px;
    border-radius: 5px;
    min-width: 30%;
    min-height: 150px;
    justify-content: space-around;
    cursor: pointer;
    span {
      line-height: 30px;
      color: #fff;
    }
    div {
      justify-content: space-between;
    }
  }
  & > :nth-child(1) {
    background-image: url(../../assets/first.png);
    background-size: cover;
  }
  & > :nth-child(2) {
    background-image: url(../../assets/second.png);
    background-size: cover;
  }
  & > :nth-child(3) {
    background-image: url(../../assets/third.png);
    background-size: cover;
  }
}
h3.title {
  padding: 0.35rem;
  border-bottom: 0;
}
.echerts-box {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  padding: 10px;
}
.span {
  position: absolute;
  /* left: 50%; */
  /* right: 50%; */
  text-align: center;
  width: 100%;
  top: 63%;
}
